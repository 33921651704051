import {AxiosError, AxiosResponse, AxiosStatic} from 'axios';
import {errorMessages} from '~/config/errorMessages.ts';
import {STRING} from '~/constants/string';
import {ApiSignServiceInterface} from '~/services/ApiSign/ApiSignServiceInterface';
import {EdsError} from '~/services/Error/EdsError';
import {ApiSignDecryptResponseType} from '~/types/http/ApiSignDecryptResponseType';
import {API_SIGN_METHODS} from '~/constants/apiSignMethods';

export class ApiSignService implements ApiSignServiceInterface {
    constructor(
        private readonly axios: AxiosStatic,
    ) {}

    async decrypt(sign: string): Promise<ApiSignDecryptResponseType> {
        const apiUrl = `${process.env.PATH_API_SIGN || ''}${API_SIGN_METHODS.DECRYPT}`;
        let response: AxiosResponse<ApiSignDecryptResponseType>;

        try {
            response = await this.axios.post(apiUrl, {sign}, {
                auth: {
                    username: process.env.API_SIGN_LOGIN as string,
                    password: process.env.API_SIGN_PASSWORD as string,
                },
            });
        } catch (e) {
            const error: AxiosError<ApiSignDecryptResponseType> = e;

            if (error.response !== undefined && error.response.data.errors !== undefined) {
                const errors = error.response.data.errors.map(error => `Decrypt error: ${error.description}`);
                throw new EdsError(errorMessages.apiSignError, errors.join(STRING.DELIMITER.DOT));
            }

            throw new EdsError(errorMessages.apiSignRequest, e);
        }

        return response.data;
    }
}
