import {errorMessages} from '~/config/errorMessages.ts';
import {EdsError} from '~/services/Error/EdsError';
import {WidgetServiceInterface} from '~/services/Widget/WidgetServiceInterface';
import {CertificateType} from '~/types/IIT/Widget/CertificateType';

export class WidgetService implements WidgetServiceInterface {
    constructor(
        private readonly widget: Widget.EndUser,
        private readonly callbackAfterAuth: (certs: CertificateType[]) => void,
    ) {}

    public addReadKeyListener(): void {
        void (async () => {
            try {
                const certificates = await this.widget.ReadPrivateKey();
                this.callbackAfterAuth(certificates);
            } catch (e) {
                throw new EdsError(errorMessages.keyRead, e.message || e);
            }
        })();
    }

    public async resetKey(): Promise<void> {
        try {
            await this.widget.ResetPrivateKey();
        } catch (e) {
            throw new EdsError(e.message || e);
        }
    }
}
