import {FormattedObjectBuilderInterface} from '~/services/ObjectFormatter/FormattedObjectBuilderInterface.ts';
import {FormattedObjectDirectorInterface} from '~/services/ObjectFormatter/FormattedObjectDirectorInterface.ts';
import {ObjectOf} from '~/types/objectTypes/ObjectOf';
import {TypeChecker} from '~/utils/checker/TypeChecker';
import {EmptyChecker} from '~/utils/checker/EmptyChecker';
import {EdsError} from '~/services/Error/EdsError';
import {errorMessages} from '~/config/errorMessages.ts';

export class FormattedObjectDirector implements FormattedObjectDirectorInterface {
    private readonly typeChecker = new TypeChecker();
    private readonly emptyChecker = new EmptyChecker();

    constructor(
        private readonly builder: FormattedObjectBuilderInterface,
        private readonly ignoreFields?: string[],
    ) {}

    build(data: ObjectOf<any>): ObjectOf<any> {
        if (this.typeChecker.isUndefined(data) || this.emptyChecker.isEmptyObject(data)) {
            throw new EdsError(errorMessages.objectEmpty);
        }

        this.builder.reset();
        this.builder.setData(data);

        if (this.typeChecker.isArray(this.ignoreFields)) {
            this.builder.removeUserIgnoredFields(this.ignoreFields as []);
        }

        this.builder.formatBuyersArray();
        this.builder.formatItemsArray();
        this.builder.formatDocumentsArrays();
        this.builder.formatLotArray();
        this.builder.deleteEnquiryPeriodFields();
        this.builder.deletePriceQuotationFields();
        this.builder.deleteProcuringEntityFields();
        this.builder.formatValue();

        return this.builder.getResult();
    }
}
