import {ObjectOf} from '~/types/objectTypes/ObjectOf';
import {UserFunctionType} from '~/types/UserFunctionType';
import {OptionsBuilderInterface} from './OptionsBuilderInterface';
import {DefaultOptionsType} from '~/types/DefaultOptionsType';

export class DefaultOptionsBuilder implements OptionsBuilderInterface {
    private options: ObjectOf<any> = {};

    getOptions(): DefaultOptionsType {
        return this.options as DefaultOptionsType;
    }

    buildIgnoreFields(fields: string[]): void {
        this.setProp('ignoreFields', fields);
    }

    buildDebug(debug: boolean): void {
        this.setProp('debug', debug);
        window.edsDebug = debug;
    }

    buildCallbackAfterAuth(fn: UserFunctionType): void {
        this.setProp('callbackAfterAuth', fn, () => {});
    }

    private setProp(name: keyof DefaultOptionsType, value: any, defaultValue?: any): void {
        this.options[name] = value || defaultValue;
    }
}
