import {AxiosStatic} from 'axios';
import {DiffPatcher} from 'jsondiffpatch';
import {DOCUMENTS_SIGN} from '~/config/compareSign';
import {errorMessages} from '~/config/errorMessages.ts';
import {EdsError} from '~/services/Error/EdsError';
import {ObjectDecoderInterface} from '~/services/ObjectDecoderInterface';
import {FormattedObjectDirectorInterface} from '~/services/ObjectFormatter/FormattedObjectDirectorInterface.ts';
import {SignServiceInterface} from '~/services/SignServiceInterface';
import {FileType} from '~/types/FileType';
import {ObjectOf} from '~/types/objectTypes/ObjectOf';
import {SignedObjectType} from '~/types/signedObjectType';
import {DocumentType} from '~/types/DocumentType';
import {UserSignOptionsType} from '~/types/UserSignOptionsType';
import {VerifyDocumentType} from '~/types/VerifyDocumentType';
import {VerifyObjectResponseType} from '~/types/VerifyObjectResponseType';
import {BlobHandler} from '~/utils/BlobHandler';
import {EmptyCheckerInterface} from '~/utils/checker/EmptyCheckerInterface';
import {TypeCheckerInterface} from '~/utils/checker/TypeCheckerInterface';
import {LoggerInterface} from '~/utils/LoggerInterface';
import {ObjectHandlerInterface} from './ObjectHandlerInterface';

const DELTA = 1;

export class ObjectHandler implements ObjectHandlerInterface {
    constructor(
        private readonly signService: SignServiceInterface,
        private readonly axios: AxiosStatic,
        private readonly typeChecker: TypeCheckerInterface,
        private readonly emptyChecker: EmptyCheckerInterface,
        private readonly objectFormatter: FormattedObjectDirectorInterface,
        private readonly objectDecoder: ObjectDecoderInterface,
        private readonly diffPatcher: DiffPatcher,
        private readonly logger: LoggerInterface,
    ) {}

    async sign(links: string[], options?: UserSignOptionsType): Promise<SignedObjectType[]> {
        this.validateLinks(links);

        const objects: ObjectOf<any>[] = await this.getObjectsData(links);

        const signPromises = objects.map(async object => ({
            id: object.id,
            sign: await this.signService.sign(JSON.stringify(object), options),
        }));

        return Promise.all(signPromises);
    }

    async verify(links: string[]): Promise<VerifyObjectResponseType[]> {
        this.validateLinks(links);

        const objects: ObjectOf<any>[] = await this.getObjectsData(links);
        const signsList: {id: string; document: DocumentType}[] = this.getSignsList(objects);

        if (this.emptyChecker.isEmptyArray(signsList)) {
            throw new EdsError(errorMessages.objectNoSign);
        }

        return Promise.all(signsList.map(async ({id, document}: VerifyDocumentType) => {
            const object = objects.find((obj: ObjectOf<any>) => obj.id === id) || {};
            const response = await this.axios.get(document.url, {responseType: 'blob'});
            const signEncoded = await BlobHandler.toBase64(response.data);
            const signPrepared = await this.signService.verify(signEncoded);
            const signData = this.objectDecoder.decode(signPrepared.data);

            const data = {
                fromSign: this.objectFormatter.build(signData.data || signData), // signData may contain "data" field
                fromDb: this.objectFormatter.build(object),
            };

            return {
                difference: this.diffPatcher.diff(data.fromSign, data.fromDb),
                signers: signPrepared.signers,
                data,
            };
        }));
    }

    private validateLinks(links: string[]): void {
        if (this.typeChecker.isUndefined(links) || !links.every(link => this.emptyChecker.isNotEmptyString(link))) {
            throw new EdsError(errorMessages.verifyLinks);
        }
    }

    private getDocumentsByType(documents: DocumentType[]| undefined, fileTypes: FileType[]): DocumentType[] {
        if (this.typeChecker.isUndefined(documents)) {
            return [];
        }

        return (documents as DocumentType[]).filter((document: DocumentType) =>
            fileTypes.some((type: ObjectOf<string>) =>
                Object.keys(type).every((key: string) => type[key] === document[key])));
    }

    private getSignsList(objects: ObjectOf<any>[]): VerifyDocumentType[] {
        return objects.map((object: ObjectOf<any>, index: number) => {
            const documents: DocumentType[] = this.getDocumentsByType(object.documents, DOCUMENTS_SIGN);

            if (this.emptyChecker.isEmptyArray(documents)) {
                this.logger.error(`Sign is not present in #${index + 1} object. Skip checking sign`);
            }

            return {
                id: object.id,
                document: documents[documents.length - DELTA],
            };
        }).filter(({document}: {document: DocumentType | undefined}) => Boolean(document));
    }

    private async getObject(url: string): Promise<ObjectOf<any>> {
        const result = await this.axios.get(url);
        const {data} = result.data;
        return data;
    }

    private async getObjectsData(links: string[]): Promise<ObjectOf<any>[]> {
        try {
            return await Promise.all(links.map(async url => this.getObject(url)));
        } catch (e) {
            throw new EdsError(errorMessages.objectAccess, e);
        }
    }
}
