import {errorMessages} from '~/config/errorMessages.ts';
import {ENCODING} from '~/constants/encoding';
import {LoggerInterface} from '~/utils/LoggerInterface';
import {ObjectDecoderInterface} from '~/services/ObjectDecoderInterface';
import {EdsError} from '~/services/Error/EdsError';
import {ObjectOf} from '~/types/objectTypes/ObjectOf';
import {Base64Interface} from '~/utils/Base64Interface';

export class ObjectDecoder implements ObjectDecoderInterface {
    constructor(
        private readonly base64: Base64Interface,
        private readonly logger: LoggerInterface,
    ) {}

    decode(data: string): ObjectOf<any> {
        let dataDecoded = this.base64.decode(data, ENCODING.UTF_8);
        this.logDecodeAttempt(ENCODING.UTF_8);

        try {
            return JSON.parse(dataDecoded);
        } catch {
            dataDecoded = this.base64.decode(data, ENCODING.UTF_16LE);
            this.logDecodeAttempt(ENCODING.UTF_16LE);
        }

        try {
            return JSON.parse(dataDecoded);
        } catch {
            dataDecoded = this.base64.decode(data, ENCODING.UTF_16);
            this.logDecodeAttempt(ENCODING.UTF_16);
        }

        try {
            return JSON.parse(dataDecoded);
        } catch {
            throw new EdsError(errorMessages.objectParse);
        }
    }

    private logDecodeAttempt(encoding: string): void {
        this.logger.log(`Trying to decode by ${encoding}`);
    }
}
