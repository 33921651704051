import {WidgetServiceInterface} from '~/services/Widget/WidgetServiceInterface';
import {WidgetUserServiceInterface} from '~/services/Widget/WidgetUserServiceInterface';

export class WidgetUserService implements WidgetUserServiceInterface {
    constructor(
        private readonly widgetService: WidgetServiceInterface,
    ) {}

    public async resetKey(): Promise<void> {
        await this.widgetService.resetKey();
        this.widgetService.addReadKeyListener();
    }
}
