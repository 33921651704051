import {UserOptionsType} from '~/types/UserOptionsType';
import {CertificateType} from '~/types/IIT/Widget/CertificateType';
import {DefaultOptionsType} from '~/types/DefaultOptionsType';
import {UserOptionsInterface} from '~/store/modules/UserOptionsInterface';

export class UserOptions implements UserOptionsInterface {
    private options: UserOptionsType = {};

    get ignoreFields(): string[] {
        return this.options.ignoreFields || [];
    }

    get debug(): boolean {
        return Boolean(this.options.debug);
    }

    get callbackAfterAuth(): (certificates: CertificateType[]) => void {
        const func = (): void => {};
        return this.options.callbackAfterAuth || func;
    }

    setOptions(options: DefaultOptionsType): void {
        this.options = options;
    }
}
