import {init, captureException, captureMessage} from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import {CaptureContext, Severity} from '@sentry/types';
import {SentryServiceInterface} from '~/services/SentryServiceInterface';

export class SentryService implements SentryServiceInterface {
    constructor() {
        init({
            dsn: process.env.SENTRY_DSN,
            release: process.env.VERSION,
            environment: process.env.MODE,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE),
        });
    }

    captureException(exception: any, captureContext?: CaptureContext): string {
        return captureException(exception, captureContext);
    }

    captureMessage(message: string, captureContext?: CaptureContext | Severity): string {
        return captureMessage(message, captureContext);
    }
}
