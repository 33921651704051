import {WidgetEndUserInterface} from '~/store/modules/WidgetEndUserInterface';

export class WidgetEndUser implements WidgetEndUserInterface {
    private _endUser?: Widget.EndUser;

    get endUser(): Widget.EndUser | undefined {
        return this._endUser;
    }

    set endUser(data: Widget.EndUser | undefined) {
        this._endUser = data;
    }
}
