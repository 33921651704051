import {TypeCheckerInterface} from '~/utils/checker/TypeCheckerInterface';
import {EmptyCheckerInterface} from '~/utils/checker/EmptyCheckerInterface';
import {TypeHandlerInterface} from '~/services/DataTypeValidator/TypeHandlerInterface';
import {Assert} from '~/utils/Assert';

export class TypeCheckFactory {
    constructor(
        private readonly typesMap: Map<string, new(
            typeChecker: TypeCheckerInterface,
            emptyChecker: EmptyCheckerInterface,
        ) => TypeHandlerInterface>,
        private readonly typeChecker: TypeCheckerInterface,
        private readonly emptyChecker: EmptyCheckerInterface,
    ) {}

    public create(type: string): TypeHandlerInterface {
        const strategyClass = this.typesMap.get(type);

        Assert.isDefined(strategyClass, {message: 'Cannot get types handler strategy', code: ''});

        return new strategyClass(this.typeChecker, this.emptyChecker);
    }
}
