import {errorMessages} from '~/config/errorMessages.ts';
import {WIDGET} from '~/config/widget';
import {WidgetFactoryInterface} from '~/services/Widget/WidgetFactoryInterface';
import {Assert} from '~/utils/Assert';

export class WidgetFactory implements WidgetFactoryInterface {
    async create(): Promise<Widget.EndUser> {
        const widget = new window.EndUser(
            WIDGET.ID.PARENT,
            WIDGET.ID.MAIN,
            WIDGET.PATH,
            window.EndUser.FormType.ReadPKey,
        );
        await this.load();
        return widget;
    }

    private load(): Promise<void> {
        const widgetIframe = document.getElementById(WIDGET.ID.MAIN);

        return new Promise(resolve => {
            Assert.isDefined(widgetIframe, errorMessages.widgetInit);
            widgetIframe.addEventListener('load', () => resolve());
        });
    }
}
