import {AxiosStatic} from 'axios';
import {errorMessages} from '~/config/errorMessages.ts';
import {pathEDS} from '~/config/pathEDS';
import {ENCODING} from '~/constants/encoding';
import {ApiSignAdapter} from '~/services/ApiSign/ApiSignAdapter';
import {ApiSignService} from '~/services/ApiSign/ApiSignService';
import {ApiSignServiceInterface} from '~/services/ApiSign/ApiSignServiceInterface';
import {ScriptReceiverAsync} from '~/services/Dom/ScriptReceiverAsync';
import {LoggerInterface} from '~/utils/LoggerInterface';
import {ObjectDecoder} from '~/services/ObjectDecoder';
import {ObjectDecoderInterface} from '~/services/ObjectDecoderInterface';
import {ObjectHandler} from '~/services/ObjectHandler';
import {ObjectHandlerInterface} from '~/services/ObjectHandlerInterface';
import {SignService} from '~/services/SignService';
import {SignServiceInterface} from '~/services/SignServiceInterface';
import {WidgetFactory} from '~/services/Widget/WidgetFactory';
import {WidgetService} from '~/services/Widget/WidgetService';
import {WidgetUserService} from '~/services/Widget/WidgetUserService';
import {WidgetUserServiceInterface} from '~/services/Widget/WidgetUserServiceInterface';
import {Store} from '~/store';
import {DefaultOptionsType} from '~/types/DefaultOptionsType';
import {ObjectOf} from '~/types/objectTypes/ObjectOf';
import {SignType} from '~/types/sign/SignType';
import {SignedObjectType} from '~/types/signedObjectType';
import {UserOptionsType} from '~/types/UserOptionsType';
import {UserSignOptionsType} from '~/types/UserSignOptionsType';
import {VerifyObjectResponseType} from '~/types/VerifyObjectResponseType';
import {Assert} from '~/utils/Assert';
import {Base64} from '~/utils/Base64';
import {TypeChecker} from '~/utils/checker/TypeChecker';
import {EmptyChecker} from '~/utils/checker/EmptyChecker';
import {EdsInterface} from './EdsInterface';
import {Logger} from '~/utils/Logger';
import {DefaultOptionsBuilder} from './OptionsBuilder/DefaultOptionsBuilder';
import {OptionsBuildDirector} from './OptionsBuilder/OptionsBuildDirector';
import {DataTypeValidator} from '~/services/DataTypeValidator/DataTypeValidator';
import {ValidationTypes} from '~/services/DataTypeValidator/ValidationTypes';
import {FormattedObjectBuilder} from '~/services/ObjectFormatter/FormattedObjectBuilder.ts';
import {FormattedObjectDirector} from '~/services/ObjectFormatter/FormattedObjectDirector.ts';
import {FormattedObjectDirectorInterface} from '~/services/ObjectFormatter/FormattedObjectDirectorInterface.ts';

export class Eds implements EdsInterface {
    public readonly version = process.env.VERSION;

    private readonly store = new Store();
    private readonly optionsBuilder = new DefaultOptionsBuilder();
    private readonly optionsDirector = new OptionsBuildDirector(this.optionsBuilder);
    private readonly typeChecker = new TypeChecker();
    private readonly emptyChecker = new EmptyChecker();
    private readonly base64 = new Base64();
    private readonly scriptReceiver = new ScriptReceiverAsync();
    private readonly apiSignAdapter = new ApiSignAdapter();
    private readonly dataTypeValidator = new DataTypeValidator();
    private readonly objectFormatterBuilder = new FormattedObjectBuilder();

    private axios?: AxiosStatic;
    private logger?: LoggerInterface;
    private signService?: SignServiceInterface;
    private objectHandler?: ObjectHandlerInterface;
    private objectDecoder?: ObjectDecoderInterface;
    private apiSignService?: ApiSignServiceInterface;
    private formattedObjectDirector?: FormattedObjectDirectorInterface;

    public async init(userOptions?: UserOptionsType): Promise<void> {
        if (!this.typeChecker.isUndefined(userOptions)) {
            this.dataTypeValidator.validate(userOptions, ValidationTypes.OBJECT);
        }

        const options = this.setupOptions(userOptions);
        const diffPatcher = await import(/* webpackChunkName: "diff_patcher" */ 'jsondiffpatch');
        const axios = await import(/* webpackChunkName: "axios_lib" */ 'axios');

        this.axios = axios.default;
        this.logger = new Logger(options.debug);
        this.apiSignService = new ApiSignService(this.axios);
        this.signService = new SignService(
            this.store.widget,
            this.apiSignService,
            this.apiSignAdapter,
            this.base64,
        );
        this.objectDecoder = new ObjectDecoder(this.base64, this.logger);
        this.formattedObjectDirector = new FormattedObjectDirector(
            this.objectFormatterBuilder,
            this.store.userOptions.ignoreFields,
        );
        this.objectHandler = new ObjectHandler(
            this.signService,
            this.axios,
            this.typeChecker,
            this.emptyChecker,
            this.formattedObjectDirector,
            this.objectDecoder,
            diffPatcher.create(),
            this.logger,
        );
    }

    public async loadWidget(): Promise<WidgetUserServiceInterface> {
        await this.scriptReceiver.insert(pathEDS + process.env.PATH_SIGN_LIBRARY);

        const widgetFactory = new WidgetFactory();
        const widget = await widgetFactory.create();
        const widgetService = new WidgetService(widget, this.store.userOptions.callbackAfterAuth);

        widgetService.addReadKeyListener();
        this.store.widget.endUser = widget;

        return new WidgetUserService(widgetService);
    }

    public async sign(data: Uint8Array | string, options?: UserSignOptionsType): Promise<Uint8Array | string> {
        this.dataTypeValidator.validate(data, [ValidationTypes.ARRAY_BUFFER, ValidationTypes.STRING]);
        Assert.isDefined(this.signService, errorMessages.libraryInit);

        return this.signService.sign(data, options);
    }

    public async signHash(data: string, options?: UserSignOptionsType): Promise<Uint8Array | string> {
        this.dataTypeValidator.validate(data, ValidationTypes.STRING);
        Assert.isDefined(this.signService, errorMessages.libraryInit);

        return this.signService.signHash(data, options);
    }

    public async signObjects(links: string[], options?: UserSignOptionsType): Promise<SignedObjectType[]> {
        this.dataTypeValidator.validate(links, ValidationTypes.ARRAY);
        Assert.isDefined(this.objectHandler, errorMessages.libraryInit);

        return this.objectHandler.sign(links, options);
    }

    public async verify(sign: string, encoding: ENCODING = ENCODING.UTF_8): Promise<SignType> {
        this.dataTypeValidator.validate(sign, ValidationTypes.STRING);
        Assert.isDefined(this.signService, errorMessages.libraryInit);

        return this.signService.verify(sign, encoding);
    }

    public async verifyObjects(links: string[]): Promise<VerifyObjectResponseType[] | Error> {
        this.dataTypeValidator.validate(links, ValidationTypes.ARRAY);
        Assert.isDefined(this.objectHandler, errorMessages.libraryInit);

        return this.objectHandler.verify(links);
    }

    public formatObject(data: ObjectOf<any>): ObjectOf<any> {
        this.dataTypeValidator.validate(data, ValidationTypes.OBJECT);
        Assert.isDefined(this.formattedObjectDirector, errorMessages.libraryInit);

        return this.formattedObjectDirector.build(data);
    }

    private setupOptions(options?: UserOptionsType): DefaultOptionsType {
        if (options === undefined) {
            return this.store.userOptions;
        }

        this.optionsDirector.buildDefaultOptions(options);
        const localData = this.optionsBuilder.getOptions();
        this.store.userOptions.setOptions(localData);
        return this.store.userOptions;
    }
}
