import {ScriptReceiver} from './ScriptReceiver';
import {ScriptReceiverInterface} from './ScriptReceiverInterface';

export class ScriptReceiverAsync implements ScriptReceiverInterface {
    private readonly scriptReceiver = new ScriptReceiver();

    insert(src: string, isAsync = true, isDefer?: boolean): Promise<void> {
        return this.scriptReceiver.insert(src, isAsync, isDefer);
    }
}
