import {errorMessages} from '~/config/errorMessages.ts';
import {ENCODING} from '~/constants/encoding';
import {ApiSignAdapterInterface} from '~/services/ApiSign/ApiSignAdapterInterface';
import {ApiSignServiceInterface} from '~/services/ApiSign/ApiSignServiceInterface';
import {EdsError} from '~/services/Error/EdsError';
import {SignServiceInterface} from '~/services/SignServiceInterface';
import {SignType} from '~/types/sign/SignType';
import {UserSignOptionsType} from '~/types/UserSignOptionsType';
import {Assert} from '~/utils/Assert';
import {Base64Interface} from '~/utils/Base64Interface';
import {WidgetEndUserInterface} from '~/store/modules/WidgetEndUserInterface';

export class SignService implements SignServiceInterface {
    constructor(
        private readonly widget: WidgetEndUserInterface,
        private readonly apiSignService: ApiSignServiceInterface,
        private readonly apiSignAdapter: ApiSignAdapterInterface,
        private readonly base64: Base64Interface,
    ) {}

    async sign(data: Uint8Array | string, userOptions: UserSignOptionsType = {}): Promise<Uint8Array | string> {
        Assert.isDefined(this.widget.endUser, errorMessages.widgetInit);

        try {
            const options = {
                external: Boolean(userOptions.external),
                asBase64String: Boolean(userOptions.asBase64String),
                signAlgorithm: window.EndUser.SignAlgo.DSTU4145WithGOST34311,
                previousSign: userOptions.previousSign || null,
                signType: window.EndUser.SignType.CAdES_X_Long,
            };

            return await this.widget.endUser.SignData(
                data,
                options.external,
                options.asBase64String,
                options.signAlgorithm,
                options.previousSign,
                options.signType,
            ) as Uint8Array | string;
        } catch (e) {
            throw new EdsError(e.message || e);
        }
    }

    async signHash(data: string, userOptions: UserSignOptionsType = {}): Promise<Uint8Array | string> {
        Assert.isDefined(this.widget.endUser, errorMessages.widgetInit);

        try {
            const options = {
                asBase64String: Boolean(userOptions.asBase64String),
                signAlgorithm: window.EndUser.SignAlgo.DSTU4145WithGOST34311,
                previousSign: userOptions.previousSign || null,
                signType: window.EndUser.SignType.CAdES_X_Long,
            };

            return await this.widget.endUser.SignHash(
                data,
                options.asBase64String,
                options.signAlgorithm,
                options.signType,
                options.previousSign,
            ) as Uint8Array | string;
        } catch (e) {
            throw new EdsError(e.message || e);
        }
    }

    async verify(sign: string, encoding?: ENCODING): Promise<SignType> {
        const decryptResponse = await this.apiSignService.decrypt(sign);
        const {data, signers} = this.apiSignAdapter.prepareDecryptResponse(decryptResponse);

        if (encoding === undefined) {
            return {data, signers};
        }

        return {
            data: this.base64.decode(data, encoding),
            signers,
        };
    }
}
