import {EdsPathFinderInterface} from './EdsPathFinderInterface';

export class EdsPathFinder implements EdsPathFinderInterface {
    find(): string {
        const POSITION_STRING_START = 0;
        const INCLUDE_SLASH = 1;
        const currentScript = document.currentScript as HTMLScriptElement;

        if (currentScript === null) {
            return '';
        }

        return currentScript.src.substr(
            POSITION_STRING_START,
            currentScript.src.lastIndexOf('/') + INCLUDE_SLASH,
        );
    }
}
