export enum apiSignSignerKeys {
    isFilled = 'bFilled',
    isTimeAvail = 'bTimeAvail',
    isTimeStamp = 'bTimeStamp',
    issuer = 'pszIssuer',
    issuerCN = 'pszIssuerCN',
    serial = 'pszSerial',
    subject = 'pszSubject',
    subjectCN = 'pszSubjCN',
    subjectOrg = 'pszSubjOrg',
    subjectOrgUnit = 'pszSubjOrgUnit',
    subjectTitle = 'pszSubjTitle',
    subjectState = 'pszSubjState',
    subjectLocality = 'pszSubjLocality',
    subjectFullName = 'pszSubjFullName',
    subjectAddress = 'pszSubjAddress',
    subjectPhone = 'pszSubjPhone',
    subjectEMail = 'pszSubjEMail',
    subjectDNS = 'pszSubjDNS',
    subjectEDRPOUCode = 'pszSubjEDRPOUCode',
    subjectDRFOCode = 'pszSubjDRFOCode',
    time = 'Time',
}

export enum apiSignTimeKeys {
    year = 'wYear',
    month = 'wMonth',
    dayOfWeek = 'wDayOfWeek',
    day = 'wDay',
    hour = 'wHour',
    minute = 'wMinute',
    second = 'wSecond',
    milliseconds = 'wMilliseconds',
}
